<template lang="pug">
include ../../../configs/template
div
  AddCertificateISC(v-if="statement.is_international && !statement?.files?.some(el => el.file_type === 'international_certificate')" :statement="statement")
  div(v-else).row
    div.col-12
      +select-validation('body.status_document', 'listStatuses', 'status', 'nameLang', '["required"]')
    div.col-12
      +select('body.is_payed', 'listPaymentStatuses', 'payment', 'nameLang')(item-value="status")

    FileDropZone(
      v-show="!statement.is_payed"
      :error-messages="$valid.validation($v.mediaFilesArray, ['required'])"
      ref="mediaContent").col-12.mt-1.pa-0

    div.col-12.mt-3
      v-btn(@click="saveEditedStatus" :loading="buttonLoader" color="success") {{ $t('save') }}
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { sailorStatementETIStatusChanging } from '@/mixins/validationRules'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  name: 'SailorCertificationStatementEditStatus',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue'),
    AddCertificateISC: () => import('./AddCertificateISC.vue')
  },
  data () {
    return {
      body: {
        status_document: this.statement.status_document,
        is_payed: null
      },
      buttonLoader: false
    }
  },

  computed: {
    ...mapGetters(['paymentStatusByStatus', 'statusChoose']),
    ...mapState({
      id: state => state.sailor.sailorId,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listPaymentStatuses: state => state.directory.paymentStatus
    }),
    listStatuses () {
      return this.statusChoose('StatementETI').filter(el => this.statement.is_payed ? el : [STATUSES_STATEMENT_CONSTANTS.eti.canceles, STATUSES_STATEMENT_CONSTANTS.eti.inProgress].includes(el.id))
    },
    mediaFilesArray () {
      if (!this.statement.is_payed) return this.$refs.mediaContent.filesArray
      else return []
    }
  },
  mounted () {
    this.body.is_payed = this.paymentStatusByStatus(this.statement.is_payed)?.[0]?.status
  },
  validations () { return sailorStatementETIStatusChanging(this) },
  methods: {
    ...mapActions(['updateCertificateStatement']),

    async saveEditedStatus () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.buttonLoader = true
      let data = {
        ...this.$route.params,
        body: { ...this.body },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_ETI'
        }
      }

      await this.updateCertificateStatement({ ...data })

      this.buttonLoader = false
    }
  }
}
</script>
